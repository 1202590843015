import { Injectable } from '@angular/core';
// import { jwtDecode, JwtPayload } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor() {
  }

  tokenIsValid(token: string) {
    console.log('token', token)
    try {
      return true
      // const tokenDecoded: any = jwtDecode(token)
      // const isExpired = Date.now() >= tokenDecoded.exp * 1000;
      // const roles = tokenDecoded['groups']
      // return !isExpired && tokenDecoded['roles'].indexOf('administrador') > -1;
    } catch (e) {
      return false;
    }
  }

}
