import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'my-superset-container',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {
  title = 'angular-with-superset';
  dashboardId: string = '';
  token: string | null = null;
  tokenIsValid = true;

  embederUrl: string = '';
  supersetHost: string = '';
  xApiKey: string = '';

  constructor(
    public route: ActivatedRoute,
    public authenticationService: AuthenticationService
  ) {
    this.dashboardId = this.route.snapshot.queryParamMap.get('dashboardId') || '';
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.supersetHost = this.route.snapshot.queryParamMap.get('supersetHost') || '';

    this.embederUrl = process.env['EMBEDER_URL'] || '';
    this.xApiKey = process.env['X_API_KEY'] || '';
  }

  async ngOnInit(): Promise<void> {
    this.tokenIsValid = this.authenticationService.tokenIsValid(this.token!);
    if (!this.tokenIsValid) {
      return;
    }

    const myDashboard = embedDashboard({
      id: this.dashboardId, // given by the Superset embedding UI
      supersetDomain: this.supersetHost,
      mountPoint: document.getElementById(
        'my-superset-container'
      ) as HTMLElement, // any html element that can contain an iframe
      fetchGuestToken: async () => await this.getSupersetToken(),
      dashboardUiConfig: {
        // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
        hideTitle: false,
        filters: {
          expanded: false,
        },
      },
    });

    myDashboard.then((data) => {
      const iframe = document.querySelector('iframe') as HTMLIFrameElement;
      if (iframe !== null) {
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = 'none';
      }
    });
  }

  private getSupersetToken = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-key': this.xApiKey,
      Authorization: `Bearer ${this.token}`,
    };

    const body = {
      dashboardId: this.dashboardId,
      supersetHost: this.supersetHost
    };

    const response = await fetch(`${this.embederUrl}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    const token = await response.json();

    return token.guestToken;
  };
}
